import React from "react";
import Layout from "../components/Layout";
import styled from "styled-components";

import Seo from '../components/Seo'

const LeadText = styled.h2`
  font-size: 1.5rem;
  max-width: 60ch;
  font-weight: 500;
  line-height: 1.2;
  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const BodyText = styled.div`
  max-width: 800px;
  margin: 4rem auto;
  @media (max-width: 768px) {
    margin: 2rem auto;
  }
  p {
    margin-bottom: 0.5em;
  }
`;

const PageWrapper = styled.div`
  padding: 1rem 4rem;
  max-width: calc(1440px + 4rem);
  margin: 0 auto;
  @media (max-width: 768px) {
    padding: 1rem 2rem;
  }
`;


const BioPage = () => {

  return (
    <>
      <Seo title="Nigel Lendon - Obituary by David Williams" description="the verbatim copy of David Williams Obituary published in the Canberra Times."/>
      <Layout noPadding>
        <article>
          <PageWrapper>
            <LeadText>
              This is a verbatim copy of the obituary written by David Williams and published in the Canberra Times on the 20th of November 2021.
            </LeadText>
            <BodyText>
              <p>The visual arts community, colleagues and friends join the family of highly regarded artist educator Nigel Lendon in expressing sorrow at his recent death.</p>
              <p>A year after being diagnosed with brain cancer, Nigel died peacefully at his Wamboin home on Sunday, October 24, 2021.</p>
              <p>A conceptual artist, Nigel will be remembered for his contribution to the visual arts education, studio practice, theoretical writing and his curatorship of exhibitions</p>
              <p>As Canberra School  of Art deputy director from 1988-2012, hge was convenor of the graduate studies program, leading to the introduction of research degrees and the PhD program when the Art School amalgamated with the ANU in 1992</p>
              <p>Nigel was a very supportive colleague and friend, an ideas person, inspirational teacher and a generous mentor.</p>
              <p>He wa a nature lover, nurturing the trees and native plants on his Wamboin property with his studio he called RKD. Nigel was an early and avid blogger with his two most notable blogs 'Iconophilia' and 'Rugs of War'. He was very knowledgeable and interested in bird life, a collector of art works, examples of 1950's design and plastic cameras which were shown at a Canberra and Art CGallery exhibition in 2019.</p>
              <p>Born in Adelaide to a well-established family,, with a GP grandfather and a father who was a professor of surgery at Adelaide University, Nigel was expected to attend medical school.</p>
              <p>However, in 1964 he abandoned medical school and enrolled at the South Australian School of Art where he encountered influential artist teachers and their netowrks with whom he remianed in contact.</p>
              <p>The first exhibited  his work in the Mildura Art Prize in 1967, at the Adelaide Contemporary Art Space and in other group exhibitions. That year he contributed his minimalist work to the Mildura Triennial Sculpture Exhibition and in 1968, his work was included in the first Nation Gallery of Victoria;s groundbreaking exhibition <i>The Field</i>, where he befriended colleagues in a conceptual artist group.</p>
              <p>In 1972 , Nigel was appointed to the new Gippsland Institute of Advanced Education to teach sculpture. the same year he was appointed to the recently established Visual Arts Board of the Australia Council, together with Udo Sellbach and Geoff Parr, who were also involved with new ideas in teaching visual arts education.</p>
              <p>in 1974, following several exhibitions in the Sydney at Bonython and Watters Falleries and again at NGV, Nigel was awarded a two year Harkness Fellowship for travel to the USA to work and study in New York. There he became involved with a recently formed art and language group of artists where he developed an interest in Muybridge's photographic images, studies of motion and stereoscopic photography.</p>
              <p>returning to Sydney in 1976, Nigel participated in the formation of key visual arts organisations including Media Action, Union Media Services Art Workers Union the National Association for the Visual Arts and the Arts Law Centre.</p>
              <p>in 1977, Nigel was recruited to join the Art Theory Department of the new Sydney College of the Arts and in 1988, he was appointed deputy director of the Canberra School of Art as a convenor of the school;s graduate program. This led to the introduction of research degrees in the visual arts and eventually to the PhD program once the school formally amalgamated with the Australian National University in 1992.</p>
              <p>Once in Canberra with a growing interest in Indigenous art and curatorial practice, Nigel worked in conjunction with colleagues Wally Caruana and Djon Mundine to research and curate the exhibition <i>The Painters of the Wagilag Sisters Story 1937 to 1997</i>. Presented at the national Gallery of Australia 1999, this exhibition was very successful and attracted significant praise from prominent national historians.</p>
              <p>Now as fellow in the ANU Centre for Cross Cultural Research, Nigel's time in Arnhem Land researching this exhibition inspired further cross-cultural interests.</p>
              <p>This time he focused on Afghanistan after he was introduced to examples of the Afghan "war rug". Working with ANU colleague Tim Bonyhady in 2002, together they curated a small exhibition <i>The Rugs of War</i>, which was shown at the Canberra School of Art Gallery. This exhibition toured to the Adelaide Festival of Arts in 2004</p>
              <p>in 2021 Nigel retired from the School of Art and returned to full-time studio practice, continuing to make sculpture</p>
              <p>Since that time, he participated in a number of survey exhibitions in prominent city galleries, including Melbourne's Heidi Museum and Gallery in 2012, the ANU Drill Hall Gallery in 2017, Sydney's Casula Powerhouse in 2018, Canberra's Museum and Art Gallery in 2020 and a series of other smaller exhibitions displayed in his Wamboin studio. Nigel's most recent studio work has been the preparation for an exhibition later this year with former art school friend Aleks Danko for presentation at Milani Gallery in Brisbane.</p>
              <p>Significantly, his most recent studio work collaborative curatorial effort has again been with Tim Bonyhady, writing the catalogue and selecting rugs for the exhibition <i>i Weave What I Have SeenL the War Rugs of Afghanistan</i>. this major exhibition opend at the Drill Hall Gallery on June 25, 2021 and remains on show until late November.</p>
              <p>It is a fitting tribute to the remarkable career of a wonderful colleague, creative artist and friend.</p>
              <p>A nationally signification artist with a distinguished career, Nigel leaves an impressive legacy of sculpture, a series of written articles, substantial exhibitions and student successes.</p>
              <p>his sustained studio practice, academic leadership and teaching stand as a reference point for a new generation of young artists.</p>
              <p>Nigel Lendon will be greatly missed by his family Colleagues, friends and past students.</p>
              <p>He is survived by his wife Pamela Mcgrath, his son Axel and brother Colin.</p>
              <p>Vale Nigel Lendon.</p>
            </BodyText>
          </PageWrapper>
        </article>
      </Layout>
    </>
  );
};

export default BioPage;
